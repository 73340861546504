import image1 from './icons/calendar-month.svg';
import image2 from './icons/flower.svg';
import image3 from './icons/food.svg';
import image4 from './icons/hanger.svg';
import image5 from './icons/microphone-variant.svg';
import image6 from './icons/music-clef-treble.svg';
import image7 from './icons/record-player.svg';
// Import other images as needed

export const links = [
  {
    label: "Planner",
    imgSrc: image1,
  },
  {
    label: "Florist",
    imgSrc: image2,
  },
  {
    label: "Caterer",
    imgSrc: image3,
  },
  {
    label: "Decor",
    imgSrc: image4,
  },
  {
    label: "MC/Host",
    imgSrc: image5,
  },
  {
    label: "Musicians",
    imgSrc: image6,
  },
  {
    label: "DJ",
    imgSrc: image7,
  },
  // Add other link objects with corresponding image sources
];
