import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./components/Pages/HomePage.js";
import SignIn from "./components/Registration/Login.js";
import SignUp from "./components/Registration/Signup";

function App() {  
  
  return (
    <Router>      
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/login" element={<SignIn/>} />
          <Route path="/signup" element={<SignUp/>} />
        </Routes>           
      </div>           
    </Router>
  );
}

export default App;
