import { useState } from "react";
import { list,list2 } from "../../assets/cards-list";
import Cards from "../../components/Cards";
import Filter from "../../components/Filter";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer";

function HomePage() {
  const [selectedFilter, setSelectedFilter] = useState(0);
  
  return (
    <div>
      <Header />
      <Filter
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      {selectedFilter === 0 ? <Cards list={list} /> : <Cards list={list2} />}
      <Footer/>
    </div>
  );
}

export default HomePage;
