// Import your images if they are stored locally
import image1 from './images/caterer01.jpg';
import image2 from './images/caterer02.jpg';
import image3 from './images/caterer03.jpg';
import image4 from './images/decor01.jpg';
import image5 from './images/decor02.jpg';
import image6 from './images/decor03.jpg';
import image7 from './images/decor04.jpg';
import image8 from './images/dj01.jpg';
import image9 from './images/dj02.jpg';
import image10 from './images/dj03.jpg';
import image11 from './images/dj04.jpg';
import image12 from './images/florist01.jpg';
import image13 from './images/florist02.jpg';
import image14 from './images/florist03.jpg';
import image15 from './images/florist04.jpg';
// Add other imports as needed

export const list2 = [
  // Caterer
  {
    rating: "4",
    desc: "Delicious Food",
    imgSrc: [
      image1,
      image2,
      image3,
      // Add other local image URLs
    ],
    price: "500",
    date: "15-20 June",
    title: "Caterer",
  },
  {
    rating: "3",
    desc: "Delicious Food",
    imgSrc: [
      image1,
      image2,
      image3,
      // Add other local image URLs
    ],
    price: "200",
    date: "15-20 August",
    title: "Caterer",
  },
  {
    rating: "3",
    desc: "Delicious Food",
    imgSrc: [
      image1,
      image2,
      image3,
      // Add other local image URLs
    ],
    price: "300",
    date: "11-15 January",
    title: "Caterer",
  },
  {
    rating: "2",
    desc: "Delicious Food",
    imgSrc: [
      image1,
      image2,
      image3,
      // Add other local image URLs
    ],
    price: "100",
    date: "11-15 January",
    title: "Caterer",
  },
  
  // Decor
  {
    rating: "4",
    desc: "Lovely Designs",
    imgSrc: [
      image4,
      image5,
      image6,
      image7,
      // Add other local image URLs
    ],
    price: "200",
    date: "1-7 April",
    title: "Decor",
  },
  {
    rating: "4",
    desc: "Lovely Designs",
    imgSrc: [
      image4,
      image5,
      image6,
      image7,
      // Add other local image URLs
    ],
    price: "200",
    date: "1-7 April",
    title: "Decor",
  },
  {
    rating: "4",
    desc: "Lovely Designs",
    imgSrc: [
      image4,
      image5,
      image6,
      image7,
      // Add other local image URLs
    ],
    price: "200",
    date: "1-7 April",
    title: "Decor",
  },
  {
    rating: "4",
    desc: "Lovely Designs",
    imgSrc: [
      image4,
      image5,
      image6,
      image7,
      // Add other local image URLs
    ],
    price: "200",
    date: "1-7 April",
    title: "Decor",
  },

  // DJ
  {
    rating: "4",
    desc: "Best Hype",
    imgSrc: [
      image8,
      image9,
      image10,
      image11,
      // Add other local image URLs
    ],
    price: "200",
    date: "5-11 October",
    title: "DJ",
  },
  {
    rating: "4",
    desc: "Best Hype",
    imgSrc: [
      image8,
      image9,
      image10,
      image11,
      // Add other local image URLs
    ],
    price: "200",
    date: "5-11 October",
    title: "DJ",
  },
  {
    rating: "4",
    desc: "Best Hype",
    imgSrc: [
      image8,
      image9,
      image10,
      image11,
      // Add other local image URLs
    ],
    price: "200",
    date: "5-11 October",
    title: "DJ",
  },
  {
    rating: "4",
    desc: "Best Hype",
    imgSrc: [
      image8,
      image9,
      image10,
      image11,
      // Add other local image URLs
    ],
    price: "200",
    date: "5-11 October",
    title: "DJ",
  },

  // Florist 
  {
    rating: "4",
    desc: "Beautiful Flowers",
    imgSrc: [
      image12,
      image13,
      image14,
      image15,
      // Add other local image URLs
    ],
    price: "200",
    date: "13-18 November",
    title: "Florist",
  },
  {
    rating: "4",
    desc: "Beautiful Flowers",
    imgSrc: [
      image12,
      image13,
      image14,
      image15,
      // Add other local image URLs
    ],
    price: "200",
    date: "13-18 November",
    title: "Florist",
  },
  {
    rating: "4",
    desc: "Beautiful Flowers",
    imgSrc: [
      image12,
      image13,
      image14,
      image15,
      // Add other local image URLs
    ],
    price: "200",
    date: "13-18 November",
    title: "Florist",
  },
  {
    rating: "4",
    desc: "Beautiful Flowers",
    imgSrc: [
      image12,
      image13,
      image14,
      image15,
      // Add other local image URLs
    ],
    price: "200",
    date: "13-18 November",
    title: "Florist",
  },
  
  // Add other objects with local image URLs
];
export const list = [
 // Caterer
 {
  rating: "3",
  desc: "Delicious Food",
  imgSrc: [
    image1,
    image2,
    image3,
    // Add other local image URLs
  ],
  price: "500",
  date: "15-20 June",
  title: "Caterer",
},
{
  rating: "4",
  desc: "Delicious Food",
  imgSrc: [
    image2,
    image3,
    image1,
    // Add other local image URLs
  ],
  price: "200",
  date: "15-20 August",
  title: "Caterer",
},
{
  rating: "3",
  desc: "Delicious Food",
  imgSrc: [
    image3,
    image2,
    image1,
    // Add other local image URLs
  ],
  price: "100",
  date: "11-15 January",
  title: "Caterer",
},
{
  rating: "3",
  desc: "Delicious Food",
  imgSrc: [
    image1,
    image2,
    image3,
    // Add other local image URLs
  ],
  price: "500",
  date: "15-20 June",
  title: "Caterer",
},

// Decor
{
  rating: "4",
  desc: "Lovely Designs",
  imgSrc: [
    image4,
    image5,
    image6,
    image7,
    // Add other local image URLs
  ],
  price: "200",
  date: "1-7 April",
  title: "Decor",
},
{
  rating: "4",
  desc: "Lovely Designs",
  imgSrc: [
    image5,
    image6,
    image7,
    image4,
    // Add other local image URLs
  ],
  price: "200",
  date: "1-7 April",
  title: "Decor",
},
{
  rating: "4",
  desc: "Lovely Designs",
  imgSrc: [
    image7,
    image6,
    image5,
    image4,
    // Add other local image URLs
  ],
  price: "200",
  date: "1-7 April",
  title: "Decor",
},
{
  rating: "4",
  desc: "Lovely Designs",
  imgSrc: [
    image6,
    image5,
    image4,
    image7,
    // Add other local image URLs
  ],
  price: "200",
  date: "1-7 April",
  title: "Decor",
},
// DJ
{
  rating: "4",
  desc: "Best Hype",
  imgSrc: [
    image8,
    image9,
    image10,
    image11,
    // Add other local image URLs
  ],
  price: "200",
  date: "5-11 October",
  title: "DJ",
},
{
  rating: "4",
  desc: "Best Hype",
  imgSrc: [
    image9,
    image10,
    image11,
    image8,
    // Add other local image URLs
  ],
  price: "200",
  date: "5-11 October",
  title: "DJ",
},
{
  rating: "4",
  desc: "Best Hype",
  imgSrc: [
    image11,
    image10,
    image9,
    image8,
    // Add other local image URLs
  ],
  price: "200",
  date: "5-11 October",
  title: "DJ",
},
{
  rating: "4",
  desc: "Best Hype",
  imgSrc: [
    image10,
    image9,
    image8,
    image11,
    // Add other local image URLs
  ],
  price: "200",
  date: "5-11 October",
  title: "DJ",
},

// Florist 
{
  rating: "4",
  desc: "Beautiful Flowers",
  imgSrc: [
    image12,
    image13,
    image14,
    image15,
    // Add other local image URLs
  ],
  price: "200",
  date: "13-18 November",
  title: "Florist",
},
{
  rating: "4",
  desc: "Beautiful Flowers",
  imgSrc: [
    image13,
    image14,
    image12,
    image15,
    // Add other local image URLs
  ],
  price: "200",
  date: "13-18 November",
  title: "Florist",
},
{
  rating: "4",
  desc: "Beautiful Flowers",
  imgSrc: [
    image15,
    image14,
    image13,
    image12,
    // Add other local image URLs
  ],
  price: "200",
  date: "13-18 November",
  title: "Florist",
},
{
  rating: "4",
  desc: "Beautiful Flowers",
  imgSrc: [
    image14,
    image13,
    image12,
    image15,
    // Add other local image URLs
  ],
  price: "200",
  date: "13-18 November",
  title: "Florist",
},

// Add other objects with local image URLs
];
