import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaCcVisa, FaCcMastercard, FaCcPaypal, FaCcAmex } from 'react-icons/fa';
import './Footer.css'; // Ensure to create a CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          <div className="footer-col">
            <h3>About Us</h3>
            <p>We are a leading multivendor platform, connecting buyers and sellers worldwide. Our mission is to provide the best marketplace experience.</p>
          </div>
          <div className="footer-col">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Shop</a></li>
              <li><a href="#">About</a></li>
              <li><a href="#">Contact</a></li>
              <li><a href="#">FAQ</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h3>Follow Us</h3>
            <ul className="social-icons">
              <li><a href="#"><FaFacebookF /></a></li>
              <li><a href="#"><FaTwitter /></a></li>
              <li><a href="#"><FaInstagram /></a></li>
              <li><a href="#"><FaLinkedinIn /></a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h3>Subscribe to our Newsletter</h3>
            <form>
              <input type="email" placeholder="Your email address" required />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
        <hr />
        <div className="footer-bottom">
          <div className="payment-methods">
            <h4>We Accept</h4>
            <ul>
              <li><FaCcVisa /></li>
              <li><FaCcMastercard /></li>
              <li><FaCcPaypal /></li>
              <li><FaCcAmex /></li>
            </ul>
          </div>
          <p className="text-center">
            © 2024 . All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
