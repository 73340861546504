// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\r\n  --black: #000;\r\n  --grey: #ddd;\r\n  --font-grey: #717171;\r\n  --white: #ffffff;\r\n  --theme: #7B68EE;\r\n  --light-grey: #a0a0a0;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: \"Nunito Sans\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap\");\r\n\r\n:root {\r\n  --black: #000;\r\n  --grey: #ddd;\r\n  --font-grey: #717171;\r\n  --white: #ffffff;\r\n  --theme: #7B68EE;\r\n  --light-grey: #a0a0a0;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: \"Nunito Sans\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
